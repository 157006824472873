import { Link } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const LegalPage = () => (
    <Layout>
        <SEO
            title="Legal"
            description="We love working with people like you. It is good for us. It is good for you."
        />
        <section className="front padded full-height wrapper" id="imprint">
            <h1>Legal</h1>
            <div>
                <p>
                    Castrum Doloris Design
                    <br />
                    by eAleph Desarrollos en Informática s.a. de c.v.
                </p>
                <p>
                    Diego Barrera
                    <br />
                    <Link to="/contact" className="link">
                        contact(at)castrum-doloris.design
                    </Link>
                    <br />
                    Priv. Fco. Goytia 40
                    <br />
                    16050 Mexico City
                    <br />
                    Mexico
                </p>
            </div>
        </section>
    </Layout>
);

export default LegalPage;
